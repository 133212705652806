import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import crosshairsImage from '../assets/images/home/crosshairs.png';
import PageLayout from '../components/PageLayout';
import mainBgImage from '../assets/images/home/glass-cubes.png';
import blogWallPaperImage from '../assets/images/grant-classifier/blog-wallpaper.png';
import largeCrosshairImage from '../assets/images/grant-classifier/crosshair-large.png';
import diskImage from '../assets/images/home/clear-disks.png';
import arrowRightIcon from '../assets/images/common/arrow-right.svg';

function GrantClassifierPage() {
  const navigate = useNavigate();

  const benefits = [
    {
      title: 'Reduced time, fewer mistakes.',
      description: "Classifying grants shouldn't require that much manual work. Free up your staff so they can devote themselves to something that truly matters, all while avoiding human errors."
    },
    {
      title: 'Spot trends quickly, and choose what to fund next.',
      description: "What topics should you fund research on? You'll be able to leverage powerful analytics to make data-based decisions and track their efficacy over time."
    },
    {
      title: 'Align your focus area and your funded projects.',
      description: "Are the projects you're currently funding in alignment with your philantropic strategy?"
    },
    {
      title: 'Evaluate proposals at a glance.',
      description: "Label your grant applications with tags to help evaluators and reviewers quickly discern what they see."
    },
  ];

  const elements = [
    {
      title: 'Tech automation',
      description: 'The Grant Classifier leverages data from OpenAlex and OpenAI to generate scientific tags and similarity scores for research work titles and abstracts in bulk'
    },
    {
      title: 'The platform',
      description: 'Upload title and abstract for multiple bodies of work related to prospective or awarded grants. The Classifier tags them with scientific concepts from reputable sources, to let you process more, faster, and better.'
    },
    {
      title: 'The integration',
      description: "Directly integrate the Classifier into your existing platforms and workflows. This way, you'll be able to access scientific tags at any point in your grant processes and within any system, saving precious time when dealing with large databases."
    },
  ];

  return (
    <PageLayout>
      <div className="page-grant-classifier">
        <img src={mainBgImage} className="main-bg-image" alt="Main background" />
        <div className="summary">
          <div className="summary-title-wrapper">
            <img className="summary-image" src={crosshairsImage} alt="Section" />
            <div className="title">
              Grants and grant applications, seamlessly sorted
            </div>
          </div>
          <div className="details">
            Evaluating and reviewing grants will be much simpler from now on.
          </div>
        </div>
        <div className="content-wrapper">
          <img className="crosshair-image" src={largeCrosshairImage} alt="Background" />
          <div className="section-with-image">
            <div className="section-text-wrapper">
              <div className="title">How We Improved JTF's Grant Classification and Trend Analysis Using Artificial Intelligence</div>
              <div className="author">By Lotus Rise</div>
              <Link className='product-link' to='/blog/grant-classifier'>
                Read More
                <img src={arrowRightIcon} alt='Arrow right' />
              </Link>
            </div>
            <img className="section-image" src={blogWallPaperImage} alt="Section" />
          </div>
          <div className="section-right-float">
            <div className="section-text-wrapper">
              <div className="title">Navigating the obstacles on your grant classification path</div>
              <div className="details">Effectively allocating grant funds to maximize impact can present substantial challenges. Here are some of the hurdles we'll help you get over:</div>
              <ul className="point-list">
                <li>Evaluating the efficacy of a large number of awarded grants at once could overwhelm your staff.</li>
                <li>Mission and strategy misalignments: each project should reflect your foundation's focus area.</li>
                <li>The difficulty of evaluation and decision-making when competitive grant applications overburden you.</li>
              </ul>
            </div>
          </div>
          <div className="section-right-float">
            <div className="section-text-wrapper">
              <div className="title">Here's how you'll benefit from the Grant Classifier</div>
              <div className="benefit-list">
                {
                  benefits.map((item) => (
                    <div className="benefit-item">
                      <div className="title">{item.title}</div>
                      <div className="description">{item.description}</div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="section-elements">
            <div className="title">
              Grant Classifier's three key elements
            </div>
            <div className="section-list">
              {
                elements.map((sectionData) => (
                  <div className="section-list-item">
                    <div className="name">{sectionData.title}</div>
                    <div className="description">{sectionData.description}</div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="section-contacts">
            <div className="info-wrapper">
              <div className="title">Get in touch</div>
              <div className="details">Do you have any questions or want to test the Grant Classifier tool? We're one message away.</div>
            </div>
            <button type="button" className="btn btn-primary" onClick={() => navigate('/contact')}>Contact Us</button>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default GrantClassifierPage;
