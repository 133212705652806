import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import mainBgImage from '../assets/images/faq/bg-main.png'

const faqSections = [
  {
    question: 'How can foundations and nonprofits benefit from working with Lotus Rise?',
    answer: 'Foundations can award responsive grants with a clear, measurable impact. Nonprofits can build the relationships they need to thrive over the long term. Both can benefit from world-class tech solutions that boost their efficiency like never before. Everyone wins when we collaborate.'
  },
  {
    question: 'Can you help us integrate new technology with our existing systems?',
    answer: 'Absolutely. We can integrate contemporary technology solutions with your existing systems to ensure a seamless transition and improved functionality without disrupting your daily operations.'
  },
  {
    question: 'What if we need a custom tech solution?',
    answer: 'We excel at creating custom tech solutions that meet your organization’s unique needs. During our consultation, we’ll work closely with you to identify your requirements and develop a solution that aligns with your goals and your budget.',
  },
  {
    question: 'How do you stay current with the latest tech trends and tools?',
    answer: 'Our team is committed to continuous learning and stays up-to-date with the latest tech trends and tools through ongoing education, certifications, and industry conferences. This ensures we deliver the most current and effective solutions to our clients.'
  },
  {
    question: 'Are your services affordable for smaller nonprofits?',
    answer: 'Yes. Not only are our services fairly priced, but we also assist nonprofits in accessing responsive funding from foundations. We’re committed to providing high-value, cost-effective solutions for organizations that are making a difference.'
  },
  {
    question: 'How easy is it to engage with Lotus Rise?',
    answer: 'We know how busy you are. At Lotus Rise, we prefer a straightforward, hassle-free approach. You reach out, and we set up a meeting to listen attentively to your needs and assess your unique situation. Then, we craft an optimal proposal addressing your challenges and maximizing your organization’s efficiency.'
  }
];

const FaqPage = () => {
  const navigate = useNavigate();

  const [sectionList, setSectionList] = useState([...faqSections]);

  const onQuestionToggle = useCallback((sectionIndex) => {
    setSectionList([...sectionList.map((section, i) => ({
      ...section,
      isOpened: sectionIndex === i ? !section.isOpened : section.isOpened
    }))])
  }, [sectionList])

  return (
    <PageLayout>
      <div className="page-faq">
        <img src={mainBgImage} className="main-bg-image" alt="Main background" />
        <div className="summary">
          <div className="title">
            The answers to your queries.
          </div>
          <div className="details">
            Here are the most common queries we get, along with their answers.
          </div>
        </div>
        <div className="content-wrapper">
          <div className="section-list">
            <div className="title">Your frequently asked questions</div>
            {
              sectionList.map((faqSection, i) => (
                <div key={i} className={clsx('section-list-item', { open: faqSection.isOpened })}>
                  <div className="question" onClick={() => onQuestionToggle(i)}>{faqSection.question}</div>
                  <div className="answer">{faqSection.answer}</div>
                </div>
              ))
            }
          </div>
          <div className="section-contact">
            <div className="title">Need further assistance?</div>
            <div className="details">Should you require additional information, please do not hesitate to reach out to our team. We're here to provide you with the answers you need.</div>
            <button type="button" className="btn btn-dark" onClick={() => navigate('/contact')}>Contact Us</button>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default FaqPage;
