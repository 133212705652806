import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import mainBgImage from '../assets/images/grant-impact-reporting/bg-main.png';
import treeIcon from '../assets/images/grant-impact-reporting/tree.svg';
import leafImage from '../assets/images/grant-impact-reporting/leaf.svg';
import boxIcon from '../assets/images/grant-impact-reporting/box.svg';
import partnerImage from '../assets/images/home/stephen.png';
import lineImage from '../assets/images/grant-impact-reporting/line.svg';
import girWallpaperImage from '../assets/images/blog/grant-impact-reporting-wallpaper.png';
import arrowRightIcon from '../assets/images/common/arrow-right.svg';

const GrantImpactReportingPage = () => {
  const navigate = useNavigate();

  const toolSections = [
    {
      title: 'Seamless UX during login.',
      description: 'Our web-based portal has SSO authentication, allowing grantees to securely log in and access their accounts. No hassle, more peace of mind.'
    },
    {
      title: 'Easy grant work input.',
      description: "Grantees can effortlessly input and track their work, even when it hasn't been published yet."
    },
    {
      title: 'Published work is populated automatically.',
      description: "Our system automatically populates the grantees' published work, reducing their administrative burden and ensuring that their output is accurately reflected."
    },
    {
      title: 'Enhance your Business Intelligence and reporting.',
      description: 'We integrate with your Business Intelligence platform, and enhance its capabilities. Generate basic reports, track key metrics, and gain valuable insights into your grants and associated works.'
    },
  ];

  return (
    <PageLayout>
      <div className="page-grant-impact-reporting">
        <img src={mainBgImage} className="main-bg-image" alt="Main background" />
        <div className="summary">
          <div className="summary-title-wrapper">
            <img className="summary-image" src={treeIcon} alt="Section" />
            <div className="title">
              Showcasing the impact of your grants is easier than ever
            </div>
          </div>
          <div className="details">
            Discover how Generative AI, Machine Learning, and advanced analytics can assist you in making more strategic grant decisions and gaining a clearer outlook into their impact.
          </div>
        </div>
        <div className="content-wrapper">
          <div className="section-with-image">
            <div className="section-text-wrapper">
              <div className="title">Our Grant Impact Reporting tool in six simple steps.</div>
              <div className="author">By Lotus Rise</div>
              <Link className='product-link' to='/blog/grant-impact-reporting'>
                Read More
                <img src={arrowRightIcon} alt='Arrow right' />
              </Link>
            </div>
            <img className="section-image" src={girWallpaperImage} alt="Section" />
          </div>
          <div className="section-decisions">
            <div className="section-image-wrapper">
              <img className="section-image" src={leafImage} alt="Section" />
            </div>
            <div className="section-text-wrapper">
              <div className="title">Moving toward data-centric grantmaking decisions</div>
              <div className="section-list">
                <div className="section-list-item">
                  <div className="title">All the information you need in one place.</div>
                  <div className="details">Your grantees can finally input and update their grant-related work easily and efficiently, including when it's not been published yet. Published work is automatically populated.</div>
                </div>
                <div className="section-list-item">
                  <div className="title">Better workflows, fewer mistakes.</div>
                  <div className="details">Your reliance on manual data entry consumes valuable time and resources and introduces the potential for omissions and mistakes. Our streamlined process makes everything go more smoothly.</div>
                </div>
                <div className="section-list-item">
                  <div className="title">Understand and showcase your grants' impact.</div>
                  <div className="details">Generate reports, track key metrics, and gain and share valuable insights on the impact your grants have had.</div>
                </div>
                <div className="section-list-item">
                  <div className="title">No more emotion-driven decisions.</div>
                  <div className="details">Currently, grant decisions are driven largely by emotion. With an accurate and comprehensive outlook on the impact of your grants, you'll be able to bid farewell to hidden and apparent biases and make data-driven decisions, instead.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-partner">
            <img className="partner-avatar" src={partnerImage} alt="Partner" />
            <div className="contact-info-wrapper">
              <div className="name">Stephen Fitzmier</div>
              <div className="position">Director of Planning & Evaluation,</div>
              <div className="company">John Templeton Foundation</div>
            </div>
            <div className="description">
              <i>“Partnering with Lotus Rise has helped us streamline our team’s evaluative workflows and pilot new technologies in ways that consistently help us better understand the impact of our funding and learn how to improve our grantmaking.”</i>
            </div>
          </div>
          <div className="section-steps">
            <div className="scheme-wrapper">
              <img className="line" src={lineImage} alt="Line" />
              <div className="circle" style={{ left: '3%', top: 'calc(100% - 22px)' }} />
              <div className="circle" style={{ left: '20%', top: 'calc(100% - 22px)' }} />
              <div className="circle middle" style={{ left: '46%', top: 'calc(77% - 5px)' }} />
              <div className="circle" style={{ left: 'calc(85% - 10px)', top: '-5%' }} />
              <div className="title-wrapper">
                <div className="title">A step by step overview of Grant Impact Reporting</div>
                <div className="details">Here's how grantees and foundations can use the Grant Impact Reporting tool:</div>
              </div>
              <div className="step-list-wrapper">
                <div className="step-wrapper" style={{ left: '3%', top: 'calc(100% + 30px)' }}>
                  <div className="name">Step 1</div>
                  <div className="description">Grantees can report on their published work for any awarded grant via our portal.</div>
                </div>
                <div className="step-wrapper" style={{ left: '20%', top: 'calc(100% - 190px)' }}>
                  <div className="name">Step 2</div>
                  <div className="description">Published work is automatically populated by our CrossRef and OpenAlex integration.</div>
                </div>
                <div className="step-wrapper" style={{ left: '46%', top: 'calc(77% + 60px)' }}>
                  <div className="name">Step 3</div>
                  <div className="description">Foundations can generate basic reports, track key metrics, and gain valuable insights into their grants and associated works.</div>
                </div>
                <div className="step-wrapper" style={{ left: 'calc(100% - 200px)', top: 'calc(-5% + 75px)' }}>
                  <div className="name">Step 4</div>
                  <div className="description">Foundations can easily monitor the status, progress, and impact of their grants, fostering collaboration and accountability with grantees.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-tool">
            <div className="title">
              A tool and the workflow that you can rely on
            </div>
            <div className="section-list">
              {
                toolSections.map((sectionData) => (
                  <div className="section-list-item">
                    <img src={boxIcon} className="image" alt="Section icon" />
                    <div className="name">{sectionData.title}</div>
                    <div className="description">{sectionData.description}</div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="section-contacts">
            <div className="info-wrapper">
              <div className="title">Get in touch</div>
              <div className="details">Do you have any questions or want to test the Grant Impact Reporting tool? We're one message away.</div>
            </div>
            <button type="button" className="btn btn-primary" onClick={() => navigate('/contact')}>Contact Us</button>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default GrantImpactReportingPage;
