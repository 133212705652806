import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PageLayout from '../components/PageLayout';
import girWallpaperImage from '../assets/images/blog/grant-impact-reporting-wallpaper.png';
import leafWallpaperImage from '../assets/images/blog/leaf-wallpaper.png';
import girImage1 from '../assets/images/blog/grant-impact-reporting-1.png';
import girImage2 from '../assets/images/blog/grant-impact-reporting-2.png';
import girImage3 from '../assets/images/blog/grant-impact-reporting-3.png';
import girImage4 from '../assets/images/blog/grant-impact-reporting-4.png';
import grantClassifierWallpaperImage from '../assets/images/blog/grant-classifier-wallpaper.png';
import artificialIntelligenceWallpaperImage from '../assets/images/blog/artificial-intelligence-wallpaper.jpg';
import technologyMissionWallpaperImage from '../assets/images/blog/technology-mission-wallpaper.jpeg';

export const blogPages = [
  {
    code: 'grant-impact-reporting',
    author: 'Lotus Rise',
    title: 'Our Grant Impact Reporting tool in six simple steps.',
    image: girWallpaperImage,
    sections: [
      {
        content:
          'In the words of Peter Drucker, “You can’t improve what you don’t measure." Your journey toward a more effective allocation of your grants begins with monitoring and evaluating the impact that they have.',
      },
      {
        content: 'Yes, but how?',
      },
      {
        content:
          "Tracking funded work manually is a tiresome endeavor where errors and omissions can occur. It's especially complicated when the work that's been funded through a grant you awarded hasn't yet been published.",
      },
      {
        content:
          "How can we make it easier for foundations like yours to streamline this process, improving the effectiveness of your awarded grants' and their impact's visibility?",
      },
      {
        content:
          "That's the question that we were trying to answer when we came up with the Grant Impact Reporting (GIR) tool.",
      },
      {
        content:
          'This unique solution will be a precious addition to your reporting toolbox. It will enable grantees to easily report on the work they are doing, and allow foundations like yours to effortlessly monitor and evaluate the outcome of the funding they are providing. Allow us to guide you on how this tool can transform your grant management in six simple steps.',
      },
      {
        title: '1. Seamless and secure access',
        content:
          "Grantees visit your foundation's website, where they access the GIR portal. Our login is simple and secure thanks to Single Sign-On (SSO) authentication. We wanted to ensure that logging in was easy to free the grantees from the hassle of remembering multiple passwords - in turn, this reduces barriers to engagement. A secure login, on the other hand, provides peace of mind. Together, they allow grantees to focus on what really matters: reporting their work.",
      },
      {
        title: '2. Easy input of unpublished work',
        content:
          'Once they have logged in, grantees can immediately start reporting on their grant-related works that have not yet been published. The Grant Impact Reporting tool’s intuitive interface makes this process straightforward and user-friendly. This way, even the less tech-savvy grantees can navigate it effortlessly. By allowing grantees to report unpublished work, your foundation gains a more comprehensive view of the research and projects in progress. This helps you to stay informed about all the efforts that your funding supports.',
        images: [girImage1, girImage2],
      },
      {
        title: 'Automatic population of published work',
        content:
          "This next step is done for you. In fact, one of the standout features of the Grant Impact Reporting tool is its ability to automatically populate published work. The system's integration with CrossRef and OpenAlex uses the grantee's email address and ORCID identifier as well as the grant's DOI number to pull the published works that the grantee has written for that specific grant, without requiring any manual input. This reduces the administrative burden on grantees and ensures that their published output is accurately reflected in your reports. This way, you can showcase the full scope of your achievements.",
      },
      {
        title: 'Integrated Business Intelligence and reporting',
        content:
          'Now that all your necessary data is in one place, you can leverage the GIR integration with your existing Business Intelligence (BI) platform to generate reports, track key metrics, and gain valuable insights into the grants you have awarded. Whether you need to produce basic reports for internal review or a more detailed analysis for your stakeholders, the Grant Impact Reporting tool enhances your BI capabilities, so that your data-driven decisions can more thoroughly reflect the real impact of your funding.',
        images: [girImage3],
      },
      {
        title: 'Comprehensive grant and work management',
        content:
          'The Grant Impact Reporting tool doesn’t just stop at data collection and reporting—it also provides you with an intuitive interface to view and manage all your grants and associated works. You can monitor the status, progress, and impact of each grant at a glance, fostering smooth collaboration and stronger accountability between grantees and your foundation. This allows you to stay on track with the success of your funding and identify areas of improvement.',
        images: [girImage4],
      },
      {
        title: "Showcasing your grants' impact",
        content:
          'Showcasing the impact of your grants has never been this easy. The GIR tool harnesses the power of AI, Machine Learning, and advanced analytics so that you can make more strategic grant decisions and gain a clearer understanding of their impact. This data-centric view of grantmaking will help you reduce hidden and apparent biases so that your funding is allocated based on solid evidence.',
      },
      {
        content:
          "The GIR tool is designed to be a reliable partner in your grant management journey. From its seamless SSO authentication to its user-friendly interface, every aspect of the tool is designed to provide a hassle-free experience for both grantees and foundations. Whether you're looking to enhance your BI reporting, reduce administrative burdens, or simply gain a clearer understanding of your grants' impact, the GIR tool is the all-in-one solution you can trust. In these six simple steps, you can gain access to all the necessary information, and make more strategic decisions based on accurate, comprehensive data.",
      },
    ],
  },
  {
    code: 'grant-classifier',
    author: 'Lotus Rise',
    title:
      "How We Improved JTF's Grant Classification and Trend Analysis Using Artificial Intelligence.",
    image: grantClassifierWallpaperImage,
    sections: [
      {
        content:
          '“Partnering with Lotus Rise has helped us streamline our team’s evaluative workflows and pilot new technologies in ways that consistently help us better understand the impact of our funding and learn how to improve our grantmaking.”',
      },
      {
        content:
          'John Templeton Foundation is a philanthropic organization that funds interdisciplinary research. Their mission is to start awe-inspiring conversations. They strive for a world where people are curious about the wonders of the universe and seek to live purposeful lives.',
      },
      {
        title: 'Challenges:',
        content:
          'JTF needed a strong and efficient mechanism to analyze and classify the numerous grant applications and academic publications they receive. Manual processes were cumbersome and inconsistent, existing proprietary meta databases were inadequate and expensive, and Machine Learning and Neuro Linguistic Programming required lots of data and excellent training sets.',
      },
      {
        content: 'This translated into a set of potential challenges:',
        list: [
          'Inefficiency in operations due to manual application processing',
          'Evaluation inconsistency and quality variability',
          'An inadequate utilization of data, with limited insights and data silos',
          'Issues with scalability due to the unsustainability of manual processes',
          'High costs if using proprietary meta-databases',
          'Data integrity and security risks in case of manual processing',
        ],
      },
      {
        content:
          'That’s why they decided to partner up with us to find an optimal way to classify their grants.',
      },
      {
        content:
          'JTF is one one of the few foundations their size to allow people to submit their ideas to them on any topic. However, they also have prioritized, strategic areas they focus on. Given that the vast majority of JTF’s grants are used to fund academic research, the John Templeton Foundation required a reliable way to decipher research trends while maintaining uniformity in their grant classification.',
      },
      {
        content:
          "Lotus Rise adapted OpenAlex's text classification tool, integrating the meta-database to add a number of topics that were particularly relevant for JTF, and making it API-callable so that it would classify any text input provided by JTF, including the titles and abstracts of the applications they received.",
      },
      {
        title: 'Our Step-by-Step Process:',
        list: [
          "Understanding JTF's unique requirements for grant classification.",
          'Adding additional, ad-hoc topics, and fine-tuning OpenAlex’s classification model.',
          'Making the classification tool API-callable.',
          'Ensuring accuracy and reliability through thorough testing.',
        ],
      },
      {
        title: 'The Results:',
        list: [
          "Lotus Rise extended OpenAlex's technology to classify text on-demand and to include additional terms in its model.",
          'JTF was able to pilot emerging classification technologies for its grant applications and academic publications.',
          'The improved classification technology produced more informative results than other classification models JTF was testing, helping JTF to identify trends in grant applications and publications it had supported.',
        ],
      },
      {
        content:
          'Future Plans: JTF and Lotus Rise will continue exploring AI and machine learning applications to further enhance John Templeton Foundation’s classification system.',
      },
      {
        content:
          "Are you ready to leverage cutting-edge technology for your organization's needs? Contact us.",
      },
    ],
  },
  {
    code: 'grant-classifier-new',
    author: 'Lotus Rise',
    title:
      "Bridging the Tech Gap to Amplify Nonprofit Organizations' Global Impact.",
    image: artificialIntelligenceWallpaperImage,
    sections: [
      {
        content:
          'My ties to the nonprofit space go way back - I’ve been working closely with them, I have invested in them, and I even founded one myself.',
      },
      {
        content:
          'I’m in awe of their efforts to create a better world, championing essential causes ranging from equality and access to healthcare to environmental preservation, human rights, and much more.',
      },
      {
        content:
          'If you ever wonder just how impactful they are, consider this: in 2020 alone, charitable contributions made by nonprofits and foundations in the United States reached nearly $450 billion. It’s a large sum, but it could be much higher. Let me tell you how.',
      },
      {
        content:
          'Foundations and nonprofit organizations are the voice of kindness in what can sometimes feel like a world driven by greed, and they should be able to access all the necessary tools to succeed in their mission. However, it’s become painfully evident, throughout the years, that most of them are far behind when it comes to adopting new technological paradigms. The work they do is critical and, unfortunately, many of their activities are limited by inadequate technological support.',
      },
      {
        content:
          'Foundations and nonprofit organizations face several barriers to entry when trying to access technology, the main ones being budgetary limitations and a lack of technical expertise that can make nonprofit organizations hesitant to embrace change, especially when overwhelmed by a multitude of options.',
      },
      {
        content:
          'Contemporary technology has the potential to empower them on different levels. It can boost their efficiency, streamline data management and reporting for smarter decision-making, deepen their engagement to drive more meaningful donations, reduce costs for a roomier budget, enhance collaboration for greater collective impact, and so much more.',
      },
      {
        content:
          'Technology is no longer a luxury that nonprofits and foundations can afford to do without, and they’re starting to realize it. The Nonprofit Technology Enterprise Network reported that 92% of nonprofits expressed that technology is crucial to the success of their organizations.',
      },
      {
        content:
          'This is where Lotus Rise comes in. We strive to close the technological gap that puts many nonprofit organizations at a disadvantage. We support foundations and nonprofits by providing access to tech solutions and relevant knowledge that help improve their performance and bring their missions to life.',
      },
      {
        content:
          'Our strategy involves the development of a marketplace through which foundations, government agencies, nonprofit organizations, volunteers, and vendors can interact. Our goal is to establish a synergy between technology and philanthropy.',
      },
      {
        content:
          'In our view, foundations and nonprofits are absolutely essential to building a better, fairer, and more sustainable future. However, for them to fully realize their potential, they have to be equipped with contemporary technological solutions that fit their needs. That way, they will have everything they require to succeed.',
      },
      {
        content:
          'Do you want to see how we apply this? Read about how we used Artificial Intelligence to improve the John Templeton Foundation’s grant classification and trend analysis.',
      },
    ],
  },
  {
    code: 'how-technology-shapes-our-mission',
    author: 'Lotus Rise',
    title: 'How Technology Shapes Our Mission',
    image: technologyMissionWallpaperImage,
    sections: [
      {
        quotation:
          "In my 30 years as an evaluator, it hasn't so much been a question of whether data was available or attainable - it has always been the need to get the information in the right hands at the right time. Appropriate technology opens the path to useful information.",
        author: 'Charles Gasper',
      },
      {
        content:
          'At Lotus Rise, we’ve built our mission around a single question: How can we help foundations and nonprofits become more impactful?',
      },
      {
        content:
          'The answer we gave ourselves saw technology as one of its two pillars. To us, it can be a powerful facilitator for social causes and a wildly underestimated resource in the philanthropic space.',
      },
      {
        content:
          'With our Co-Founders Neeraj Vir and Charles Gasper having 55 cumulative years of experience in tech and in the nonprofit space, we know just how impactful the right tools can be.',
      },
      {
        content:
          'Providing foundations and nonprofit organizations with access to contemporary tech solutions can empower them to make better decisions and enhance their efficiency, leading to an even greater and longer-lasting positive impact.',
      },
      {
        title: 'Why technology matters',
        content:
          'Nonprofit organizations and foundations’ devotion to elevating their constituents is challenged by the highly complex environment they operate. Economic constraints, limited resources, and frenetic technological advancements put more pressure than ever on foundations and nonprofit organizations to be nimble and efficient.',
      },
      {
        content:
          'Technology could play a major part in their modernization, and yet, 68% of nonprofits say they are struggling to progress digitally overall due to their finances, headspace, and capacity. With a plethora of tools to choose from, foundations and nonprofit organizations may also feel overwhelmed.',
      },
      {
        content:
          'Large nonprofits and foundations seem to be better equipped, but too many of the smaller organizations are being left behind. Democratizing access to the right contemporary solutions could help level the field, and multiply the impact of philanthropic organizations at large.',
      },
      {
        content:
          'Our mission at Lotus Rise is to make state-of-the-art technology available and accessible to nonprofits and foundations. Whether through predictive analytics, intuitive user experience, process reengineering, or more, we provide the expertise these organizations need to streamline their efforts and focus on what truly matters.',
      },
      {
        title: 'Data-informed decision-making',
        content:
          'Perhaps one of the most important areas in which technology can help foundations and nonprofit organizations is data-informed decision-making.',
      },
      {
        content:
          'Gathering data correctly, decoding it in a consistent way, and utilizing it to make informed decisions is highly resource-intensive. However, it is a non-negotiable for those same organizations that need to be highly economical with their resources to ensure a maximized impact.',
      },
      {
        content:
          'For instance, foundations can leverage technology to measure donor retention rates or program outcomes. Our Grant Impact Reporting tool is helping the John Templeton Foundation understand the impact of their grants and identify those initiatives that produced the strongest output. This will enable them to optimally award their grants to make their desired outcomes manifest.',
      },
      {
        title: 'Optimizing operations',
        content:
          'Besides improving their decision-making process, technology can help foundations and nonprofit organizations function more effectively.',
      },
      {
        content:
          'Their teams find themselves juggling a number of tasks, from managing programs to communicating with donors, funders, and volunteers, or preparing reports. Coordinating all of this and more without access to the right tools can lead to the waste of precious resources and lost opportunities.',
      },
      {
        content:
          "A growing number of nonprofits are adopting budgeting and planning tech solutions, but this can affect other functions as well. Generative AI can be used to quickly generate dashboards and reports, automation can take care of back-office tasks, and AI agents can speed up data analysis, and review and synthesize proposals and reports, thereby minimizing the staff's workload.",
      },
      {
        content:
          'The right tools can assist foundations and nonprofit organizations with managing their general operations and facilitating their internal communication and collaboration. This way, foundations and nonprofits can direct their efforts and time toward advocating for change.',
      },
      {
        title: 'How technology and collaboration intertwine',
        content:
          'Our mission goes beyond merely providing access to the right tools or guidance for our customers. Our goal is to create a collaborative environment with mutual benefits for all nonprofit organizations, foundations, vendors, and volunteers.',
      },
      {
        content: 'This is where collaboration and technology intertwine.',
      },
      {
        content:
          'The community will be aimed at eliminating the barriers that organizations tend to build around themselves within the philanthropic sector. They will be able to share their knowledge on strategies, resources, and experiences. This exchange will lead to collective advancement..',
      },
      {
        content:
          'The collaborative space will also bring organizations together encouraging responsive funding, so that the foundations’ resources can meet the nonprofits’ technological needs, thus, effectively multiplying their impact.',
      },
      {
        title: 'Conclusion',
        content:
          'At Lotus Rise, we strive to empower nonprofits and foundations to use technology to build a better and kinder future.',
      },
      {
        content:
          'That’s the beauty of technology – it can do great things, but only if we join forces.',
      },
      {
        content:
          'So, we invite all foundations and nonprofits to join us on our journey. Whether you are focused on enhancing operational efficiency, exploring innovative grantmaking strategies, or simply seeking to make a greater impact in your communities, your insights and contributions will help shape the COMMUNITY we are building.',
      },
    ],
  },
  {
    code: 'our-core-values',
    author: 'Lotus Rise',
    title: 'Our core values',
    image: leafWallpaperImage,
    sections: [
      {
        title:
          'Unlocking Potential in the Social Impact Sector: How Our Values Inspire Transformation',
        content:
          'The social impact sector is no stranger to uphill battles. Purpose-driven entities like yours bravely face a combination of limited resources, endless demands, and the constant pressure to demonstrate impact every day.',
      },
      {
        content:
          'Access to the right tools and environment can make all the difference. The right solutions help you operate more efficiently and ensure that your social impact not only grows but stands the test of time.',
      },
      {
        content:
          'More specifically, technology can help you streamline operations, analyze data, and communicate your impact effectively, whereas a collaborative ecosystem can spark new ideas, connect you with valuable resources tailored to your unique situation, and even open doors to responsive funding opportunities.',
      },
      {
        title: 'How Nonprofit Struggles Shaped Our Values',
        content:
          "Over the decades, our Co-Founders, Charles Gasper and Neeraj Vir, observed a trend: despite the tremendous efforts foundations and nonprofits put into their work, they consistently lagged behind in adopting modern technology and struggled to access collaborative environments lasting longer than their projects' timeline.",
      },
      {
        content:
          'To this day, while 74% of nonprofit leaders recognize the importance of digital transformation, only 12% have achieved digital maturity.',
      },
      {
        content:
          'Technology often feels foreign, overwhelming, or financially out of reach. Many nonprofit leaders believe their limited resources are better spent directly on their mission rather than investing in infrastructure. This mindset often forces organizations to rely on ad-hoc, manual solutions that aren’t scalable, ultimately stunting growth and diluting impact.',
      },
      {
        content:
          'Low operational efficiency translates into more time spent on administration, leaving less time for mission-critical work. The lack of collaborative systems leads to duplicated efforts and fewer opportunities for innovation.',
      },
      {
        content:
          'In other words, your limited resources and busy schedules might lead you to believe that adopting new technology will create more headaches than benefits. But as our Co-founder Charles Gasper says, “When you only focus on funds and human capital, you are sitting on a stool with two legs.”',
      },
      {
        content:
          'When we founded Lotus Rise, we wanted to help nonprofits fill this gap and reach their full potential. We knew that the solutions we offered had to reflect the same principles that drive your mission. After decades of working alongside nonprofits and foundations, we realized these values form the foundation for every successful partnership and solution. The nonprofit leaders we spoke to confirmed the need for solutions rooted in empowerment, community, and integrity.',
      },
      {
        content:
          'With this in mind, we built Lotus Rise around a set of core values that reflect both the needs of nonprofits and the mission-driven work they do every day.',
      },
      {
        content:
          'Let’s take a closer look at the values that define us and guide the solutions we create.',
      },
      {
        title: 'The Principles That Guide Us',
        complexList: [
          {
            title: 'Empowerment',
            content:
              'By providing expert guidance and a suite of tailored solutions, we aim to amplify your impact. This goes beyond operational efficiency—it’s about unlocking your potential to make real change in your communities.',
          },
          {
            title: 'Innovation',
            content:
              'Innovation is at the core of everything we do at Lotus Rise. Technology like Web3, Generative AI, Machine Learning, and advanced analytics aren’t just buzzwords—they’re invaluable tools in your hands. These tools can give you greater clarity in your decision-making process, optimize your operations, and help you communicate the impact of your work to the outside world. For example, when the John Templeton Foundation needed a better way to classify grant applications and academic publications, we adapted a text classification tool tailored to their unique needs. This improved their efficiency, provided consistent evaluations, and offered valuable insights into research trends—all while reducing their manual workload. Our tailored solutions include everything from strategic consultations to tech platform training and advanced tools designed specifically with nonprofits in mind.',
          },
          {
            title: 'Integrity',
            content:
              'We see honesty and integrity as core elements of our ethos. We believe that in order to build sustainable partnerships with nonprofits and foundations, we must first earn their trust and respect. That’s why we prioritize transparency and strive to build long-term relationships with the organizations we support.',
          },
          {
            title: 'Community',
            content:
              'We are deeply community-oriented. Nonprofits like yours thrive when they operate within a connected ecosystem where knowledge-sharing and cooperation are at the center. We envision a future where nonprofits, foundations, service providers, and volunteers are interconnected, fostering a dynamic, mutually beneficial environment. A connected nonprofit ecosystem enables shared resources, faster innovation, and collaborative problem-solving—ensuring that your work has a greater and longer-lasting impact.',
          },
        ],
      },
      {
        title: 'Conclusion',
        content:
          'As we grow, these principles will remain at the heart of everything we do. They are the key to helping nonprofits like yours make the most of technology and transform the world.',
      },
      {
        content:
          'With the right tools, support, and community, your mission can reach new heights.',
      },
      {
        content: 'Your values drive your mission—our solutions amplify it.',
      },
      {
        content:
          'At Lotus Rise, we’re here to help you bridge the gaps, embrace innovation, and build a future where your social impact is as enduring as it is powerful. Let’s unlock your nonprofit’s full potential together.',
      },
    ],
  },
];

const BlogPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const blogData = useMemo(() => {
    const blogCode = params.code;

    return blogPages.find((item) => item.code === blogCode);
  }, [params.code]);

  return (
    <PageLayout>
      <div className='page-blog'>
        {blogData ? (
          <>
            <img
              src={blogData.image}
              className='main-bg-image'
              alt='Main background'
            />
            <div className='summary'>
              <div className='title'>{blogData.title}</div>
            </div>
            <div className='content-wrapper'>
              <div className='author'>By {blogData.author}</div>
              {blogData.sections.map((section) => (
                <div
                  className={clsx('blog-section', {
                    quotation: section.quotation,
                  })}
                >
                  {section.quotation ? (
                    <p className='section-content'>
                      <i>
                        {section.quotation}
                        {section.author ? ` - ${section.author}` : ''}
                      </i>
                    </p>
                  ) : null}
                  {section.title ? (
                    <h4 className='title'>{section.title}</h4>
                  ) : null}
                  {section.content ? (
                    <p className='section-content'>{section.content}</p>
                  ) : null}
                  {section.extraContent ? (
                    <p className='section-content'>{section.extraContent}</p>
                  ) : null}
                  {section.list ? (
                    <ul className='list'>
                      {section.list.map((listItem) => (
                        <li className='list-item'>{listItem}</li>
                      ))}
                    </ul>
                  ) : null}
                  {section.complexList ? (
                    <div className='complex-list'>
                      {section.complexList.map((complexListItem) => (
                        <div className='section'>
                          <h4>{complexListItem.title}</h4>
                          <div>{complexListItem.content}</div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {section.images ? (
                    <div className='image-list'>
                      {section.images.map((image) => (
                        <img className='image' src={image} alt='Blog section' />
                      ))}
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </>
        ) : (
          <div>Blog page not found</div>
        )}
      </div>
    </PageLayout>
  );
};

export default BlogPage;
