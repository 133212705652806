import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/images/header/logo.svg';
import menuIcon from '../assets/images/header/menu.png';

function Header() {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const menuItems = [
    {
      label: 'About Us',
      route: '/about-us'
    },
    {
      label: 'Services',
      route: '/services'
    },
    {
      label: 'Products',
      route: '/products',
      isMenu: true,
      childItems: [
        {
          label: 'Grant Impact Reporting',
          route: '/grant-impact-reporting'
        },
        {
          label: 'Grant Classifier',
          route: '/grant-classifier'
        },
      ],
    },
    {
      label: 'Grant Impact Reporting',
      route: '/grant-impact-reporting',
      mobileOnly: true
    },
    {
      label: 'Grant Classifier',
      route: '/grant-classifier',
      mobileOnly: true
    },
    {
      label: 'Bloom',
      route: '/marketplace',
    },
    {
      label: 'Blog',
      route: '/blog'
    },
  ];

  const onLinkClicked = () => {
    document.body.style.overflow = "auto";
  }

  return (
    <>
      <div className="header">
        <div className="content-wrapper">
          <div className="content">
            <Link to="/" relative='path'>
              <img
                src={logo}
                className="logo"
                alt="Main Logo"
              />
            </Link>
            <div className='navbar'>
              {
                menuItems.map((menuItem, i) => (
                  menuItem.isMenu ? (
                    <Dropdown
                      key={i}
                      onMouseEnter={() => setDropdownOpen(true)}
                      onMouseLeave={() => setDropdownOpen(false)}
                      show={dropdownOpen}
                    >
                      <Dropdown.Toggle as={Link} to={menuItem.route} className='navbar-item'>
                        {menuItem.label}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {menuItem.childItems.map((childItem, j) => (
                          <Dropdown.Item key={j} as={Link} to={childItem.route}>
                            {childItem.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <Link key={i} to={menuItem.route} className={clsx('navbar-item', { mobile: menuItem.mobileOnly })}>
                      {menuItem.label}
                    </Link>
                  )
                ))
              }
            </div>
            <button className="btn btn-menu" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button">
              <img
                src={menuIcon}
                className="menu-icon"
                alt="Menu icon"
                data-bs-toggle="offcanvas" data-bs-target="#nav-menu"
              />
            </button>
            <button type="button" className="btn btn-outline-success btn-contact" onClick={() => navigate('/contact')}>
              <div className="text">Contact</div>
            </button>
          </div>
        </div>
      </div>
      <div className="offcanvas offcanvas-start offcanvas-header" tabIndex="-1" id="nav-menu" data-bs-keyboard="false" data-bs-backdrop="true">
        <div className="offcanvas-header">
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body px-0">
          <div className="navbar">
            {
              menuItems.map((menuItem, i) => (
                <Link key={i} to={menuItem.route} relative='path' onClick={onLinkClicked}>
                  <div className='navbar-item'>{menuItem.label}</div>
                </Link>
              ))
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
