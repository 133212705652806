import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import mainBgImage from '../assets/images/marketplace/bg-main.png'
import bloomIcon from '../assets/images/marketplace/bloom.svg'
import bridgingGapImage from '../assets/images/marketplace/bridging-gap.png'
import decisionsImage from '../assets/images/marketplace/decisions.png'
import ceoAvatar from '../assets/images/marketplace/ceo.jpg'

const MarketplacePage = () => {
  const navigate = useNavigate();

  return (
    <PageLayout>
      <div className="page-marketplace">
        <img src={mainBgImage} className="main-bg-image" alt="Main background" />
        <div className="summary">
          <div className="summary-title-wrapper">
            <img className="summary-image" src={bloomIcon} alt="Bloom" />
            <div className="title">
              A collaboration hub for advancing tech in nonprofits and foundations
            </div>
          </div>
          <div className="details">
            We're building a collective for nonprofits, foundations, service providers, and volunteers to work together and improve lives through technology.
          </div>
        </div>
        <div className="content-wrapper">
          <div className="section-with-image reverse">
            <div className="section-text-wrapper">
              <div className="title">Bridging the gap, together</div>
              <div className="details">We are striving to bridge the technology gap for organizations that spend invaluable effort into elevating others, but are lacking in their own infrastructure.
                We're building a platform where nonprofits and foundations can get access to resources to get their technology needs fulfilled.</div>
            </div>
            <img className="section-image" src={bridgingGapImage} alt="Section" />
          </div>
          <div className="section-with-image small">
            <div className="section-text-wrapper">
              <div className="title">The reasons why we decided to embark in this project</div>
              <div className="details">An insufficient technological infrastructure can hinder the efforts of nonprofits and foundations alike. The hub we're building will let them:</div>
              <ul className="point-list">
                <li>Access a comprehensive list of tech services.</li>
                <li>Learn about and pilot new cutting-edge solutions.</li>
                <li>Benefit from a supportive environment of peers and collaborators.</li>
              </ul>
            </div>
            <img className="section-image" src={decisionsImage} alt="Section" />
          </div>
          <div className="section-market-principles">
            <div className="section-text-wrapper">
              <div className="title">Our guiding principles</div>
              <div className="details">The core values that inform our every move.</div>
            </div>
            <div className="point-list">
              <div className="point-list-item">
                <div className="title">Empowerment</div>
                <div className="details">To reach their full potential, foundations and nonprofits require the right combination of tools and expert guidance. Our solutions enable them to make data-based decisions and amplify their impact.</div>
              </div>
              <div className="point-list-item">
                <div className="title">Innovation</div>
                <div className="details">We truly believe that technology has the power to change the world for the better. Our cutting-edge solutions support nonprofits' and foundations' efforts to make the world a better place.</div>
              </div>
              <div className="point-list-item">
                <div className="title">Integrity</div>
                <div className="details">When it comes to relationships, we always take the long view. For them to last, they must be rooted in trust and respect. That's why we embrace transparency, honesty, and accountability in all our interactions.</div>
              </div>
              <div className="point-list-item">
                <div className="title">Community</div>
                <div className="details">We strongly believe that foundations and nonprofits will thrive in a vibrant ecosystem where they connect, exchange knowledge, and collaborate. Ultimately, it's what helps us all drive change.</div>
              </div>
            </div>
          </div>
          <div className="section-conversation">
            <div className="section-text-wrapper">
              <div className="title">Your insights can shape our ecosystem</div>
              <div className="details">Are you a nonprofit or foundation? Let's talk. We'd love to hear your thoughts on the collective we're building to ensure that it'll meet your needs.</div>
            </div>
            <div className="btn-container">
              <button type="button" className="btn btn-dark" onClick={() => navigate('/contact')}>Start a conversation</button>
            </div>
          </div>
          <div className="section-quotation">
            <img src={ceoAvatar} className="avatar" alt="CEO" />
            <div className="title-wrapper">
              <div className="ceo-name">Neeraj Vir</div>
              <div className="position">Founder & CEO, Lotus Rise</div>
            </div>
            <div className="details">
              <i>“The work that foundations and nonprofits do is an invaluable service to humanity. After years spent collaborating with organizations in this space, I've come to realize that a lack of tech is hindering their efforts immensely. That's why at Lotus Rise we are striving to democratize the access to technology. Adopting contemporary solutions will bolster the operational efficiency of foundations and nonprofits at large.”
              </i>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MarketplacePage;
