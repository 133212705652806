import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import mainBgImage from '../assets/images/services/bg-main.jpg'
import guidanceImage from '../assets/images/services/guidance.png'
import solutionsImage from '../assets/images/services/solutions.png'
import causeImage from '../assets/images/services/cause.png'
import bagIcon from '../assets/images/services/bag.png'
import lightIcon from '../assets/images/services/light.png'
import soundIcon from '../assets/images/services/sound.png'

const directionList = [
  {
    icon: bagIcon,
    label: 'Strategic business consulting.'
  },
  {
    icon: lightIcon,
    label: 'Contemporary tech solutions.'
  },
  {
    icon: soundIcon,
    label: 'Marketing and branding services.'
  },
]

const ServicesPage = () => {
  const navigate = useNavigate();

  return (
    <PageLayout>
      <div className="page-services">
        <img src={mainBgImage} className="main-bg-image" alt="Main background" />
        <div className="summary">
          <div className="title">
            Services you need to achieve your goals
          </div>
          <div className="details">
            We provide business consulting, contemporary tech solutions, and marketing services to help foundations and nonprofits go the distance.
          </div>
        </div>
        <div className="content-wrapper">
          <div className="section-directions">
            <div className="title">We support you on three fronts:</div>
            <div className="direction-list">
              {
                directionList.map((listItem, i) => (
                  <div key={i} className="direction-list-item">
                    <img src={listItem.icon} className="direction-icon" alt="List marker" />
                    <div className="direction-name">{listItem.label}</div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="section-with-image">
            <div className="section-text-wrapper">
              <div className="title">The advisory you need</div>
              <div className="details">We'll help you optimize your operations, develop an effective strategy, and reach your goals while being economical with your resources.</div>
            </div>
            <img className="section-image" src={guidanceImage} alt="Section" />
          </div>
          <div className="section-with-list">
            <div className="title">How strategic business consulting will benefit you</div>
            <div className="details">Our experienced team of consultants will guide your foundation or nonprofit through every step of your journey.</div>
            <div className="section-content-list">
              <div className="section-content-list-item">
                <div className="subtitle">Strategic Planning</div>
                <div className="description">Develop effective strategies to drive organizational growth.</div>
              </div>
              <div className="section-content-list-item">
                <div className="subtitle">Resource management</div>
                <div className="description">Optimize your resources and improve sustainability.</div>
              </div>
              <div className="section-content-list-item">
                <div className="subtitle">Operational Efficiency</div>
                <div className="description">Streamline processes and enhance productivity.</div>
              </div>
            </div>
          </div>
          <div className="section-with-image reverse">
            <div className="section-text-wrapper">
              <div className="title">Contemporary tech solutions</div>
              <div className="details">Contemporary tech solutions. Leverage modern technology for greater impact, from custom software development to generative AI.</div>
            </div>
            <img className="section-image" src={solutionsImage} alt="Section" />
          </div>
          <div className="section-with-list">
            <div className="title">How our contemporary tech solutions will benefit you:</div>
            <div className="section-content-list">
              <div className="section-content-list-item">
                <div className="subtitle">Modern tech solutions:</div>
                <div className="description">We offer app modernization, web app and portal development, as well as data analytics.</div>
              </div>
              <div className="section-content-list-item">
                <div className="subtitle">Artificial Intelligence & Machine Learning:</div>
                <div className="description">We leverage technology to intelligently structure your databases for simplified funding decisions and improved allocation of your grants.</div>
              </div>
              <div className="section-content-list-item">
                <div className="subtitle">Data visualization:</div>
                <div className="description">We'll enable you to gain more clarity and make data-informed decisions.</div>
              </div>
            </div>
          </div>
          <div className="section-with-image">
            <div className="section-text-wrapper">
              <div className="title">Let your cause be known</div>
              <div className="details">Our marketing services include branding, content, social media marketing, and more, to help your nonprofit or foundation reach its target audience so you can achieve your goals.</div>
            </div>
            <img className="section-image" src={causeImage} alt="Section" />
          </div>
          <div className="section-with-list">
            <div className="title">How our marketing and branding services will benefit you:</div>
            <div className="details">Our marketing team is dedicated to helping your organization make a lasting impact.</div>
            <div className="section-content-list">
              <div className="section-content-list-item">
                <div className="subtitle">Branding that stands out:</div>
                <div className="description">Our branding experts will create a unique and memorable identity for your nonprofit or foundation to ensure that you can stand out in a crowded space.</div>
              </div>
              <div className="section-content-list-item">
                <div className="subtitle">Social media marketing strategies:</div>
                <div className="description">Our targeted social media campaigns will engage your audience and drive meaningful results.</div>
              </div>
              <div className="section-content-list-item">
                <div className="subtitle">Compelling content:</div>
                <div className="description">Our team will craft compelling content that tells your foundation's or nonprofit's story and inspires action from your supporters.</div>
              </div>
            </div>
          </div>
          <div className="section-contacts">
            <div className="title">Get in touch</div>
            <div className="details">Do you have any questions on our services? Send us a message, and we'll schedule a call right away.</div>
            <button type="button" className="btn btn-dark" onClick={() => navigate('/contact')}>Contact Us</button>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default ServicesPage;
