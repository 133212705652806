import React from 'react';
import { Link } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import bloomingFlowerImage from '../assets/images/home/blooming-flower.png';
import leafImage from '../assets/images/home/leaf.png';
import marbleDiskImage from '../assets/images/home/marble-disk.png';
import cubesImage from '../assets/images/home/glass-cubes.png';
import crosshairsImage from '../assets/images/home/crosshairs.png';
import contactLensesImage from '../assets/images/home/contact-lenses.png';
import diskImage from '../assets/images/home/clear-disks.png';
import arrowRightIcon from '../assets/images/common/arrow-right.svg';

function ProductListPage() {
  return (
    <PageLayout>
      <div className="outer-box">
        <div className="products-section-1">
          <img
            src={diskImage}
            alt="Contact Lenses"
            className="products-section-1-photo"
          />
        </div>

        <div className="products-section-2">
          <div className="products-section-2-right">
            <div className="products-section-2-right-inner-text">
              We have set out to empower foundations to more efficiently meet their goals by leveraging contemporary technology. We have developed tools that enable foundations to make more accurate grantmaking decisions, offer tailored solutions to address their technological needs, and are building a hub to foster collaboration between themselves and nonprofits.
            </div>
          </div>
        </div>

        <div className="products-section-3">
          <div className="products-section-3-left">
            <div className="products-section-3-left-container">
              <img src={leafImage} alt="Leaf" className="leaf-image" />
              <div className="products-section-3-header">
                The Grant Impact Reporting tool
              </div>
              <div className="products-section-3-text">
                Efficiently assess grants and applications, thus reducing human error and freeing up staff from monotonous tasks. Award grants to mission-aligned projects, and make data-informed decisions quickly and efficiently.
              </div>
              <Link className='product-link' to='/grant-impact-reporting'>
                Learn More
                <img src={arrowRightIcon} alt='Arrow right' />
              </Link>
            </div>
          </div>
          <div className="products-section-3-right">
            <img src={marbleDiskImage} alt="Contact Lenses" className="photo" />
          </div>
        </div>

        <div className="products-section-4">
          <div className="products-section-4-right">
            <div className="products-section-4-right-content">
              <img src={crosshairsImage} alt="Crosshairs" className="leaf-image" />
              <div className="products-section-4-right-header">
                The Grant Classifier Tool
              </div>
              <div className="products-section-4-right-text">
                Understand and showcase the real impact of the grants you award.
                Replace emotion-driven choices with evidence-based decisions.
              </div>
              <Link className='product-link' to='/grant-classifier'>
                Learn More
                <img src={arrowRightIcon} alt='Arrow right' />
              </Link>
            </div>
          </div>
          <div className="products-section-4-left">
            <img src={cubesImage} alt="Contact Lenses" className="photo" />
          </div>
        </div>

        <div className="products-section-5">
          <div className="products-section-5-right">
            <div className="products-section-5-right-header">
              <img
                src={bloomingFlowerImage}
                alt="Blooming Flower"
                className="leaf-image"
              />
              <div className="products-section-5-right-header">
                The Lotus Rise Bloom
              </div>
              <div className="products-section-5-right-text">
                We're building a highly innovative hub for nonprofits,
                foundations, service providers, and volunteers to work together
                and improve lives through technology.
              </div>
              <Link className='product-link' to='/marketplace'>
                Learn More
                <img src={arrowRightIcon} alt='Arrow right' />
              </Link>
            </div>
          </div>
          <div className="products-section-5-left">
            <img src={contactLensesImage} alt="Contact Lenses" className="products-section-5-photo" />
          </div>
        </div>

        <div className="products-section-6">
          <div className="products-section-6-content">
            <div className="products-section-6-header">Lead the Change</div>
            <div
              className="products-section-6-text"
            >
              Your thoughts could make a huge difference in shaping our collective. We can't wait to get to know you and incorporate your perspective.
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default ProductListPage;
