import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/images/header/logo.svg";
import xIcon from '../assets/images/footer/x.svg';
import linkedInIcon from '../assets/images/footer/linked-in.svg';

function Footer() {
  const menuItems = [
    {
      label: 'About Us',
      route: '/about-us'
    },
    {
      label: 'Products',
      route: '/products'
    },
    {
      label: 'FAQ',
      route: '/faq'
    },
    {
      label: 'Contact Us',
      route: '/contact'
    },
  ];

  const socialNetworkLinks = [
    /*
    {
      icon: facebookIcon,
      width: 21,
      height: 21,
      url: 'https://facebook.com'
    },
    {
      icon: instagramIcon,
      width: 19,
      height: 19,
      url: 'https://instagram.com'
    },
    */
    {
      icon: xIcon,
      width: 18,
      height: 17,
      url: 'https://x.com'
    },
    {
      icon: linkedInIcon,
      width: 18,
      height: 18,
      url: 'https://www.linkedin.com/company/lotus-rise'
    },
    /*
    {
      icon: youtubeIcon,
      width: 20,
      height: 14,
      url: 'https://youtube.com'
    },
    */
  ];

  return (
    <div className='footer'>
      <div className='content-wrapper'>
        <Link to="/" relative='path'>
          <img
            src={logo}
            className='logo'
            alt='Footer Logo'
          />
        </Link>
        <div className='navbar'>
          {
            menuItems.map((menuItem, i) => (
              <Link key={i} to={menuItem.route} relative='path'>
                <div className='navbar-item'>{menuItem.label}</div>
              </Link>
            ))
          }
        </div>
        <div className='social-network-wrapper'>
          {
            socialNetworkLinks.map((link, i) => (
              <Link key={i} to={link.url} relative='path'>
                <img
                  src={link.icon}
                  alt='Social Network Icon'
                  width={`${link.width}px`}
                  height={`${link.height}px`}
                />
              </Link>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default Footer;
