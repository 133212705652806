import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import mainBgImage from '../assets/images/about-us/bg-main.png';
import flowerBgImage from '../assets/images/about-us/bg-flower.png';
import offeringImage from '../assets/images/about-us/offering.png';
import lotusLogo from '../assets/images/about-us/lotus.png';
import arrowRightIcon from '../assets/images/common/arrow-right.svg';
import timelineImage from '../assets/images/about-us/timeline.png';
import PageLayout from '../components/PageLayout';
import Principles from '../components/Principles';
import anuragMathurAvatar from '../assets/images/about-us/anurag-mathur.png';
import placeholderAvatar from '../assets/images/about-us/avatar-placeholder.png';
import charlesGasperAvatar from '../assets/images/about-us/charles-gasper.png';
import davidMendonezAvatar from '../assets/images/about-us/david-mendonez.png';
import lisaDorseyAvatar from '../assets/images/about-us/lisa-dorsey.png';
import neerajVirAvatar from '../assets/images/about-us/neeraj-vir.jpg';
import sandeepOhriAvatar from '../assets/images/about-us/sandeep-ohri.png';
import stephenFitzmierAvatar from '../assets/images/about-us/stephen-fitzmier.png';
import sumitaAroraAvatar from '../assets/images/about-us/sumita-arora.png';
import supamitChaiyaAvatar from '../assets/images/about-us/supamit-chiaya.png';
import warrenStreetAvatar from '../assets/images/about-us/warren-street.png';
import olegZharkikhAvatar from '../assets/images/about-us/oleg-zharkikh.png';
import blueLinkedInIcon from '../assets/images/about-us/linked-in-blue.svg';

const AboutUsPage = () => {
  const principles = [
    {
      title: 'Empowerment',
      description: 'To reach their full potential, foundations and nonprofits require the right combination of tools and expert guidance. Our solutions enable them to make data-based decisions and amplify their impact.'
    },
    {
      title: 'Innovation',
      description: "We truly believe that technology has the power to change the world for the better. Our cutting-edge solutions support nonprofits' and foundations' efforts to make the world a better place."
    },
    {
      title: 'Integrity',
      description: "When it comes to relationships, we always take the long view. For them to last, they must be rooted in trust and respect. That's why we embrace transparency, honesty, and accountability in all our interactions."
    },
    {
      title: 'Community',
      description: "We strongly believe that foundations and nonprofits will thrive in a vibrant ecosystem where they connect, exchange knowledge, and collaborate. Ultimately, it's what helps us all drive change."
    }
  ];

  const timelineItems = [
    {
      year: 2014,
      description: 'After extensive work with nonprofits and foundations, our founder Neeraj started thinking about how technology could enhance their effectiveness. He wanted to find a way for nonprofit organizations to unite and exponentially increase their impact.'
    },
    {
      year: 2019,
      description: "Neeraj's dream concretized itself into a collaboration with the John Templeton Foundation. We supported them as they piloted new technologies and found ad-hoc solutions to optimize their grant-related operations."
    },
    {
      year: 2023,
      description: 'We started formally building the Lotus Rise team, with our board members including seasoned technologists and esteemed professionals with an active involvement in the nonprofit space. This gave us the opportunity to look at the issue from different perspectives at once.'
    },
  ];

  const advisorList = [
    {
      name: 'Lisa Dorsey',
      position: 'Sr Director of Practice Management, FIS',
      avatar: lisaDorseyAvatar,
      description: "Lisa is a prominent figure in the financial technology solutions sector. Lisa was President of the Charles A. Melton Arts and Education Center Board, a foundation that operates as a community-oriented establishment, catering to the requirements of individuals historically sidelined and excluded from societal participation due to factors such as their economic standing, gender, or race, for 14 years. Lisa has played a pivotal role in helping all citizens regardless of race or economic status, flourish. Lisa's ability to unite strategic vision with genuine empathy is a driving force in fostering positive change and empowering individuals through our foundation's mission.",
      linkedInUrl: 'https://www.linkedin.com/in/lisa-dorsey-a2303113/',
      xUrl: ''
    },
    {
      name: 'Stephen Fitzmier',
      position: 'Director, Planning & Evaluation, John Templeton Foundation',
      avatar: stephenFitzmierAvatar,
      description: "Steve is the Director of Planning and Evaluation at the John Templeton Foundation, a foundation whose vision is to emerge as a worldwide force for uncovering insights that enhance the betterment of humanity. In a past life, Steve ran a technology company that connected Western funders with needs in the East. Steve’s extensive knowledge of how foundations operate aids our foundation's vision to better aid foundations’ and nonprofits’ tech ecosystem.",
      linkedInUrl: 'https://www.linkedin.com/in/sfitzmier/',
      xUrl: ''
    },
    {
      name: 'Anurag Mathur',
      position: 'Global Head of Fintech Partnerships, Google',
      avatar: anuragMathurAvatar,
      description: "Anurag is a FinTech executive with a long history of scaling growth at B2B SaaS businesses. Anurag brings expertise as an operator, board advisor, and investor. He has experience in product strategy, building alliances, monetization, and driving growth at scale. Anurag is a key player in the growth and development of Lotus Rise.",
      linkedInUrl: 'https://www.linkedin.com/in/anuragmathur/',
      xUrl: ''
    },
    {
      name: 'Sandeep Ohri',
      position: 'CEO, CY Vision',
      avatar: sandeepOhriAvatar,
      description: "Sandeep, with a wealth of experience, is a seasoned, strategic, and outcome-focused leader in Product Strategy and Product Management. His expertise lies in guiding companies to construct and promote exceptional products, leading to substantial revenue expansion. At Lotus Rise, Sandeep guides our product roadmap, collaborating seamlessly with top-tier engineering, content, and quality teams to achieve remarkable outcomes.",
      linkedInUrl: 'https://www.linkedin.com/in/sandeepohri1/',
      xUrl: ''
    },
    {
      name: 'Warren Street',
      position: 'Former SVP of Cloud Services, FIS',
      avatar: warrenStreetAvatar,
      description: "Warren is a dynamic entrepreneurial leader, boasting a wealth of experience within the Financial Services industry. His unwavering commitment to fostering growth is exemplified by his current role on the board of the Terranova Boarding School in Zambia. Founded by the Street family over two decades ago, this institution was established with a singular, noble mission: to provide education to the underserved population of Zambia. Over the years, the school has transformed the lives of thousands of children and has significantly enriched the socioeconomic fabric of the community. Warren's vibrant and compassionate personality radiates through his leadership, guiding us unequivocally toward the pursuit of creating a meaningful and lasting positive impact.",
      linkedInUrl: 'https://www.linkedin.com/in/warren-street/',
      xUrl: ''
    },
  ];

  const leaderList = [
    {
      name: 'Charles Gasper',
      position: 'Co-founder',
      avatar: charlesGasperAvatar,
      comment: "When you only focus on funds and human capital, you are sitting on a stool with two legs. By incorporating technology and relationships, you'll stabilize your output and ensure lasting social impact. By utilizing the solutions offered by Lotus Rise, foundations can award impactful, responsive grants, nonprofits can build lasting relationships, and both can access world-class tech solutions for greater efficiency. Everybody wins.",
      linkedInUrl: '',
      xUrl: ''
    },
    {
      name: 'David Mendonez',
      position: 'Alliances',
      avatar: davidMendonezAvatar,
      comment: "The most fulfilling thing we can do in life is to give back by harnessing our talents. I'm proud to be on a team of hard-working, kind-hearted, seasoned professionals who share that view. At Lotus Rise, our wish is to make the lives of those who strive for positive change easier, so they can spend more time making a difference. And that's something I'm excited to be a part of.",
      linkedInUrl: '',
      xUrl: ''
    },
  ];

  const teamMemberList = [
    {
      name: 'Neeraj Vir',
      position: 'Product & Tech Strategy',
      avatar: neerajVirAvatar,
      linkedInUrl: 'https://www.linkedin.com/in/neerajvir/',
      xUrl: '',
      websiteUrl: '',
      isCentered: true,
    },
    {
      name: 'David Mendonez',
      position: 'Alliances',
      avatar: davidMendonezAvatar,
      linkedInUrl: 'https://www.linkedin.com/in/david-mendonez/',
      xUrl: '',
      websiteUrl: ''
    },
    {
      name: 'Charles Gasper',
      position: 'Business Strategy',
      avatar: charlesGasperAvatar,
      linkedInUrl: 'http://linkedin.com/in/charlesgasper',
      xUrl: '',
      websiteUrl: ''
    },
    {
      name: 'Sumita Arora',
      position: 'Legal & Contracts',
      avatar: sumitaAroraAvatar,
      linkedInUrl: 'https://www.linkedin.com/in/sumita-arora-5467692a/',
      xUrl: '',
      websiteUrl: ''
    },
    {
      name: 'Oleg Zharkikh',
      position: 'Lead Architect',
      avatar: olegZharkikhAvatar,
      linkedInUrl: 'https://www.linkedin.com/in/oleg-zharkikh-10b2626b/',
      xUrl: '',
      websiteUrl: ''
    },
    {
      name: 'Supamit Chaiya',
      position: 'Lead Developer',
      avatar: supamitChaiyaAvatar,
      linkedInUrl: 'https://www.linkedin.com/in/supamit-chaiya/',
      xUrl: '',
      websiteUrl: ''
    },
  ];

  return (
    <PageLayout>
      <div className="page-about-us">
        <img src={mainBgImage} className="main-bg-image" alt="Main background" />
        <div className="summary">
          <div className="title">
            Driven by a commitment to innovation
          </div>
          <div className="details">
            We firmly believe in technology's ability to facilitate positive change. Organizations devoted to uplifting others sometimes face infrastructural limitations. We're here to change that.
          </div>
          <img
            src={flowerBgImage}
            className="flower-bg"
            alt="Background"
          />
        </div>
        <div className="content-wrapper">
          <div className="section-with-image reverse">
            <div className="section-text-wrapper">
              <div className="title">Our comprehensive offering</div>
              <div className="details">We strive to empower foundations and nonprofits to effortlessly leverage technology and more efficiently meet their goals. That's why we're building the one truly comprehensive platform for them to get all their technology needs fulfilled.</div>
            </div>
            <img className="section-image" src={offeringImage} alt="Section" />
          </div>
          <div className="section-principles">
            <div className="title-wrapper">
              <p className="title">Our guiding principles</p>
            </div>
            <div className="principle-list">
              {principles.map((item) => {
                return <Principles title={item.title} description={item.description} />;
              })}
            </div>
          </div>
          <div className="section-ceo">
            <img
              className="lotus-logo"
              src={lotusLogo}
              alt="Lotus logo"
            />
            <div className="details">“The work that foundations and nonprofits do benefits the whole planet. We have a responsibility to make their lives easier. After years spent collaborating with organizations in this space, I've come to realize how big of a blocker a poor tech stack can be. That's why we strive to democratize access to technology to boost the operational efficiency of foundations and nonprofits at large.”</div>
            <div className="title-wrapper">
              <div className="ceo-name">Neeraj Vir</div>
              <div className="position">Founder & CEO, Lotus Rise</div>
            </div>
          </div>
          <div className="section-story" >
            <div className="title">
              Our story thus far
            </div>
            <div className="timeline-wrapper">
              <img
                src={timelineImage}
                className="timeline-image"
                alt="Timeline"
              />
              <div className="timeline-item-wrapper">
                {
                  timelineItems.map((item) => (
                    <div className="timeline-item">
                      <div className="title">{item.year}</div>
                      <p className="description">{item.description}</p>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="section-advisors">
            <div className="title">Introducing our strategic advisors</div>
            <div className="advisor-list">
              {
                advisorList.map((item) => (
                  <div className="advisor-wrapper">
                    <img className="advisor-avatar" src={item.avatar} alt="Avatar" />
                    <div className="advisor-info-wrapper">
                      <div className="name-wrapper">
                      <Link to={item.linkedInUrl} relative='path' target="_blank" rel="noopener noreferrer">
                        <img
                          src={blueLinkedInIcon}
                          alt='LinkedIn'
                          width="18"
                          height="18"
                        />
                      </Link>
                        <div className="name">{item.name}</div>
                      </div>
                      <div className="description">{item.description}</div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="section-leaders">
            <div className="title">A few words from our leaders</div>
            <div className="leader-list">
              {
                leaderList.map((item) => (
                  <div className="leader-item">
                      <img className="leader-avatar" src={item.avatar} alt="Avatar" />
                      <div className="leader-info-wrapper">
                        <div className="name">{item.name}</div>
                        <div className="position">{item.position}</div>
                      </div>
                      <div className="comment">“{item.comment}”</div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="section-team">
            <div className="title">The hearts and minds that nurture your growth</div>
            <div className="member-list">
              {
                teamMemberList.map((item) => (
                  <div className="member-item">
                      <img className={clsx('member-avatar', { centered: item.isCentered })} src={item.avatar} alt="Avatar" />
                      <div className="member-info-wrapper">
                        <div className="name">{item.name}</div>
                        <div className="position">{item.position}</div>
                        <Link to={item.linkedInUrl ?? 'https://linkedin.com'} relative='path' target="_blank" rel="noopener noreferrer">
                        <img
                          src={blueLinkedInIcon}
                          alt='LinkedIn'
                          width="18"
                          height="18"
                        />
                      </Link>
                      </div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="section-contact">
            <div className="title">Lead the change</div>
            <div className="details">Your thoughts could make a real difference in shaping our collective. We can't wait to get to know you and unveil your perspective.</div>
            <Link className="product-link" to="/contact">Start a conversation<img src={arrowRightIcon} alt="Arrow right" /></Link>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default AboutUsPage;
