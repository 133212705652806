import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import PageLayout from '../components/PageLayout';
import mainBgImage from '../assets/images/contact/bg-main.png';

const emailRegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

const defaultContactInfo = {
  fullName: '',
  email: '',
  message: ''
};

const ContactPage = () => {
  const [contactInfo, setContactInfo] = useState({
    ...defaultContactInfo
  });
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [showMessageSentLabel, setShowMessageSentLabel] = useState(false);

  const onValueChange = useCallback((name, value) => {
    setContactInfo((currentContactInfo) => ({
      ...currentContactInfo,
      [name]: value
    }))
  }, [])

  const isFormValid = useMemo(() => {
    return contactInfo.email.length > 0 && contactInfo.fullName.length > 0 && contactInfo.message.length > 0 && emailRegExp.test(contactInfo.email);
  }, [contactInfo]);

  const submitForm = useCallback(async () => {
    if (!isFormValid) {
      return;
    }

    try {
      setIsRequestInProgress(true);

      await axios.post(`${process.env.REACT_APP_CONTACT_URL}/send-email`, {
        email: contactInfo.email,
        fullName: contactInfo.fullName,
        message: contactInfo.message
      });

      setContactInfo({
        ...defaultContactInfo
      });

      setShowMessageSentLabel(true);

      setTimeout(() => {
        setShowMessageSentLabel(false);
      }, 2000);
    } catch (e) {
      console.error('Failed to send email')
    } finally {
      setIsRequestInProgress(false);
    }
  }, [isFormValid, contactInfo]);

  return (
    <PageLayout>
      <div className="page-contact">
        <img src={mainBgImage} className="main-bg-image" alt="Main background" />
        <div className="summary">
          <div className="title">
            How may we help you?
          </div>
          <div className="details">
            We're here to answer any questions you may have. Contact us today.
          </div>
        </div>
        <div className="content-wrapper">
          <div className="contact-form-wrapper">
            <div className="contact-form">
              <input className="form-control contact-input" type="text" placeholder="Full Name" value={contactInfo.fullName} onChange={(e) => onValueChange('fullName', e.target.value ?? '')} />
              <input className="form-control contact-input" type="email" placeholder="Email" value={contactInfo.email} onChange={(e) => onValueChange('email', e.target.value ?? '')} />
              <textarea className="form-control contact-textarea" type="text" placeholder="Message" value={contactInfo.message} onChange={(e) => onValueChange('message', e.target.value ?? '')} />
            </div>
            <button type="button" className="btn-submit" onClick={submitForm} disabled={!isFormValid}>
              {
                isRequestInProgress ? (
                  <div className="spinner-border text-dark" role="status">
                    <span className="sr-only"></span>
                  </div>
                ) : 'Send'
              }
            </button>
            <div className={clsx('info-message text-success', { show: showMessageSentLabel })}>Your message has been sent</div>
          </div>
          <div className="contact-info-wrapper">
            <div className="title">Leave us a message.</div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default ContactPage;
