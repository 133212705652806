import React from 'react';
import Header from './Header';
import Footer from './Footer';

function PageLayout({ children }) {
  return (
    <div className="page-layout">
      <Header />
      <div className="page-content">
        { children }
      </div>
      <Footer />
    </div>
  );
}

export default PageLayout;
