import React from 'react';

function Principles({ title, description }) {
  return (
    <div className="principle-list-item">
      <p className="title">
        {title}:
      </p>
      <p className="details">
        {description}
      </p>
    </div>
  );
}

export default Principles;
