import React, { useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import PageLayout from '../components/PageLayout';
import { blogPages } from './BlogPage';
import arrowRightIcon from '../assets/images/common/arrow-right.svg';

const BlogListPage = () => {
  return (
    <PageLayout>
      <div className='page-blog list'>
        <div className='summary'>
          <div className='title'>Innovation, inspiration, and our vision.</div>
          <div className='details'>
            Discover cutting-edge technology, find inspiration in our thought
            leadership pieces, and learn more about who we are.
          </div>
        </div>
        <div className='blog-list-wrapper'>
          {blogPages.map((blogData) => (
            <div key={blogData.code} className='blog-list-item'>
              <img src={blogData.image} className="article-wallpaper" alt='Blog section' />
              <div className='blog-article-summary'>
                <div className="blog-article-main-details">
                  <div className='title'>{blogData.title}</div>
                  <div className='author'>By Lotus Rise</div>
                </div>
                <Link className='product-link' to={`/blog/${blogData.code}`}>
                  Read More
                  <img src={arrowRightIcon} alt='Arrow right' />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </PageLayout>
  );
};

export default BlogListPage;
